import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';

class Analytics {
  // AI Assistant Events
  aiAssistant = {
    /** @param {'lender-question'|'lender-search'} questionType */
    /** @param {String} region */
    /** @param {'Alternate'|'Bank'|'Credit Union'|'Monoline'} lenderType */
    /** @param {Array} lenders */
    askQuestion: (questionType, region, lenderType, lenders) =>
      trackAiAssistant('ask_question', {
        question_type: questionType,
        region,
        lender_type: lenderType,
        lenders,
      }),

    /** @param {Array} lenders */
    filterByLender: (lenders) => trackAiAssistant('filter_by_lender', { lenders }),

    /** @param {'Alternate'|'Bank'|'Credit Union'|'Monoline'} lenderType */
    lenderTypeSelect: (lenderType) => trackAiAssistant('lender_type_select', { lender_type: lenderType }),

    /** @param {'lender-question'|'lender-search'} questionType */
    questionTypeClick: (questionType) => trackAiAssistant('question_type_click', { question_type: questionType }),

    /** @param {String} region */
    regionSelect: (region) => trackAiAssistant('region_select', { region }),
  };

  // AltLending Events
  altLending = {
    /** @param {Number} lenderId */
    /** @param {String} loanOption */
    backToResults: (lenderId, loanOption) =>
      trackAltLending('back_to_results', {
        lender_id: lenderId,
        loan_option: loanOption,
      }),

    backToSearch: () => trackAltLending('back_to_search'),

    clearFilters: () => trackAltLending('clear_filters'),

    /** @param {Numer} lenderId */
    /** @param {String} loanType */
    /** @param {String} loanOption */
    /** @param {Number} solutionId */
    contactFormSubmit: (lenderId, loanType, loanOption, solutionId) =>
      trackAltLending('contact_form_submit', {
        lender_id: lenderId,
        loan_type: loanType,
        loan_option: loanOption,
        solution_id: solutionId,
      }),

    /** @param {Object} filter */
    filterChange: (filter) => trackAltLending('filter_change', filter),

    /** @param {Number} lenderId */
    /** @param {String} loanOption */
    /** @param {Number} solutionId */
    finmoCTAClick: (lenderId, loanOption, solutionId) =>
      trackAltLending('finmo_cta_click', {
        lender_id: lenderId,
        loan_option: loanOption,
        solution_id: solutionId,
      }),

    /**
     * Track changes to solution card layout.
     * @param {'card'|'table'} layout
     */
    layoutView: (layout) => trackAltLending(`${layout}_layout_change`),

    /** @param {Number} lenderId */
    /** @param {String} loanOption */
    /** @param {Number} solutionId */
    solutionCardClick: (lenderId, loanOption, solutionId) =>
      trackAltLending('solution_card_click', {
        lender_id: lenderId,
        loan_option: loanOption,
        solution_id: solutionId,
      }),

    /** @param {'contacts_tab_click'|'loan_details_tab_click'} action */
    /** @param {Number} lenderId */
    /** @param {String} loanOption */
    solutionTabClick: (action, lenderId, loanOption) =>
      trackAltLending(action, {
        lender_id: lenderId,
        loan_option: loanOption,
      }),

    /** @param {String} loanType */
    /** @param {String} province */
    /** @param {String} location */
    /** @param {String} loanOption */
    search: (loanType, province, location, loanOption) =>
      trackAltLending('search', {
        loan_type: capitalize(loanType),
        province,
        location,
        loan_option: loanOption,
      }),

    /**
     * Track when alternative lending tooltips are opened.
     * @param {String} filter
     */
    tooltipDisplay: (filter) => trackAltLending('tooltip_display', { filter }),

    /** @param {Number} lenderId */
    websiteClick: (lenderId) => () => trackAltLending('website_click', { lender_id: lenderId }),
  };

  // Billing Events
  billing = {
    /**
     * Track when users downgrades a subscription.
     * @param {String} plan
     */
    downgrade: (plan) => trackBilling('cancel_subscription', { plan }),

    /**
     * Track when users resume a downgraded subscription.
     * @param {String} plan
     */
    resume: (plan) => trackBilling('resume_subscription', { plan }),

    /**
     * Track when users set their payment method.
     * @param {String} page
     */
    setPayment: () => trackBilling('set_payment_method'),

    /**
     * Track when users subscribe to a plan.
     * @param {String} plan
     */
    subscribe: (plan) => trackBilling('subscribe', { plan }),

    /**
     * Track when users switch their plan between yearly and monthly.
     * @param {String} plan
     */
    switch: (plan) => trackBilling('switch_subscription', { plan }),

    /**
     * Track when users update their payment method.
     * @param {String} page
     */
    updatePayment: () => trackBilling('update_payment_method'),

    /**
     * Track when users delete their payment method.
     * @param {String} page
     */
    deletePayment: () => trackBilling('delete_payment_method'),
  };

  // Compare Events
  compare = {
    backButtonClick: () => trackCompare('back_button_click'),

    clearAll: () => trackCompareMenu('clear_all'),

    /** @param {Boolean} isOpeningSidebar */
    menuIconClick: (isOpeningSidebar) =>
      trackCompareMenu('icon_click', {
        state: isOpeningSidebar ? 'open' : 'close',
      }),

    /** @param {String} fields */
    reportSubmit: (fields) => trackCompare('create_report_submit', { fields }),

    /** @param {Boolean} isOpen */
    reportModal: (isOpen) => trackCompare('create_report_click', { state: isOpen ? 'open' : 'close' }),

    /** @param {String} policy */
    policyCollapse: (policy) => trackCompare('policy_collapse', { policy }),

    /** @param {String} policy */
    policyExpand: (policy) => trackCompare('policy_expand', { policy }),

    /** @param {Number} productCount */
    start: (productCount) => trackCompareMenu('compare_start', { count: productCount }),
  };

  // LenderDetails Events
  lenderDetails = {
    /**
     * Track when users click on a lender card on the lender page.
     * @param {Number} lenderId
     */
    cardClick: (lenderId) => () => trackLenderDetails('lender_card_click', { lender_id: lenderId }),

    /**
     * Track when users click on the lender details tab controls.
     * @param {Number} lenderId
     * @param {'contacts'|'documents'|'guidelines'|'rates'} tab
     */
    tabClick: (lenderId, tab) => () => trackLenderDetails(`${tab}_tab_change`, { lender_id: lenderId }),
  };

  // Navigation Events
  navigation = {
    onDesktop: trackNavigation('desktop'),
    onMobile: trackNavigation('mobile'),
  };

  // Policy Events
  policy = {
    /**
     * Track when users click on a policy card link.
     * @param {Number} lenderId
     * @param {'details'|'view rates'} target
     */
    cardClick: (lenderId, target) => () => trackPolicy('policy_card_click', { lender_id: lenderId, target }),

    /**
     * Tracks when users filter policies by region.
     * @param {String} region
     */
    filterByRegion: (region) => trackPolicy('filter_by_region', { region }),
  };

  // ProductSearch Events
  productSearch = {
    filter: trackFilter,

    /**
     * Debounced version of trackFilter for use with text fields.
     * @return {Function}
     */
    filterDebounced: debounce(trackFilter, 400),

    /**
     * Tracks when filter panel is closed.
     * @param {'rates|policies'} category
     */
    filterPanelClose: (category = 'rates') =>
      track(category === 'rates' ? 'product_search' : 'policy')('filter_panel_close'),

    /**
     * Tracks when filter panel is opened.
     * @param {'rates|policies'} category
     */
    filterPanelOpen: (category = 'rates') =>
      track(category === 'rates' ? 'product_search' : 'policy')('filter_panel_open'),

    /**
     * Tracks when filter panel 'Show Results' button is clicked.
     * @param {'rates|policies'} category
     */
    filterPanelShowResultsClick: (category = 'rates') =>
      track(category === 'rates' ? 'product_search' : 'policy')('filter_panel_show_results'),

    /**
     * Track changes to lender and product card layout.
     * @param {'card'|'table'} layout
     */
    layoutView: (layout) => trackProductSearch(`${layout}_layout_change`),

    /**
     * Tracks clicks on the lender cards in the products view.
     * @param {Number} lenderId
     */
    lenderCardClick: (lenderId) => trackProductSearch('lender_card_click', { lender_id: lenderId }),

    /**
     * Track lender click from Rate Card
     * @param {String} lenderName
     */
    rateCardLenderClick: (lenderId) => trackProductSearch('rate_card_lender_click', { lender_id: lenderId }),

    /**
     * Track rate details click from Rate Card
     * @param {Number} lenderId
     */
    rateCardRateClick: (lenderId) => trackProductSearch('rate_card_rate_click', { lender_id: lenderId }),

    rateCompareLimitReached: () => trackProductSearch('compare_limit_reached'),

    /**
     * Track rate card comparison selection toggle with resulting status.
     * @param {Boolean} isSelecting
     * @param {Boolean} lenderId
     */
    rateCompareSelect: (isSelecting, lenderId) =>
      isSelecting
        ? trackProductSearch('compare_select', { lender_id: lenderId })
        : trackProductSearch('compare_deselect'),

    rateCompareSidebarUnselect: () => trackProductSearch('compare_sidebar_deselect'),

    /**
     * Track saved search operations.
     */
    savedFilters: {
      close: () => trackProductSearch('saved_filter_close'),
      create: () => trackProductSearch('saved_filter_create'),
      delete: () => trackProductSearch('saved_filter_delete'),
      open: () => trackProductSearch('saved_filter_open'),
    },

    /**
     * Track changes to product sorting in lender view.
     * @param {'featured'|'lender'|'rate'} order
     */
    sort: (order) => {
      trackProductSearch(`${order}_sort`);
    },

    /**
     * Track when product tooltips are opened.
     * @param {String} filter
     */
    tooltipDisplay: (filter) => {
      trackProductSearch('tooltip_display', { filter });
    },

    /**
     * Track changes to product view-by options.
     * @param {String} path The path associated with the view change
     */
    view: (path) => {
      trackProductSearch(path.endsWith('lenders') ? 'lender_view_change' : 'rate_view_change');
    },
  };

  // Promotions Events
  promotions = {
    /**
     * Track clicks on promotion banner.
     * @param {String} lenderName
     * @param {String} page
     */
    adBannerClick: (lenderName, page) => trackPromotions('ad_banner_click', { lender_name: lenderName, page }),
  };

  // Scenarios Events
  scenarios = {
    /**
     * Track when a borrower is added to a comparison.
     * @param {String} borrowerCount
     */
    addBorrower: (borrowerCount) => trackScenarios('add_borrower', { borrower_count: borrowerCount }),

    /**
     * Track when a custom product is added to a comparison.
     * @param {'Product Selection'|'Scenario Builder'} page
     */
    addCustomProduct: (page) => trackScenarios('add_custom_product', { page }),

    /**
     * Track when a product is added to a comparison.
     * @param {String} lenderName
     * @param {String} productName
     */
    addProduct: (lenderName, productName) =>
      trackScenarios('add_product', { lender_name: lenderName, product_name: productName }),

    /**
     * Track when a scenario is added to a product.
     * @param {String} lenderName
     * @param {String} productName
     */
    addScenario: (lenderName, productName) =>
      trackScenarios('add_scenario', { lender_name: lenderName, product_name: productName }),

    /**
     * Track when a scenario is set as the base scenario for a comparison.
     * @param {String} lenderName
     * @param {String} productName
     */
    changeBase: (lenderName, productName) =>
      trackScenarios('change_base', { lender_name: lenderName, product_name: productName }),

    /**
     * Track clicks on the public comparison link on the management dashboard.
     */
    clickPublicLink: () => trackScenarios('click_public_link'),

    /**
     * Track when a comparison is started.
     */
    comparisonStart: () => trackScenarios('comparison_start'),

    /**
     * Track when a comparison's public link is copied to the clipboard.
     * @param {'Comparison Management'|'Scenario Preview'} page
     */
    copyPublicLink: (page) => trackScenarios('copy_public_link', { page }),

    /**
     * Track when a comparison is first published and a public link is generated.
     */
    createAndPublishLink: () => trackScenarios('create_and_publish_link'),

    /**
     * Track when a new comparison is created.
     */
    createComparison: () => trackScenarios('create_comparison'),

    /**
     * Track when a borrower is removed from a comparison.
     * @param {String} borrowerCount
     */
    deleteBorrower: (borrowerCount) => trackScenarios('delete_borrower', { borrower_count: borrowerCount }),

    /**
     * Track when a comparison is deleted.
     */
    deleteComparison: () => trackScenarios('delete_comparison'),

    /**
     * Track when a product is removed from a comparison.
     * @param {String} lenderName
     * @param {String} productName
     */
    deleteProduct: (lenderName, productName) =>
      trackScenarios('delete_product', { lender_name: lenderName, product_name: productName }),

    /**
     * Track when a scenario is removed from a product.
     * @param {String} lenderName
     * @param {String} productName
     */
    deleteScenario: (lenderName, productName) =>
      trackScenarios('delete_scenario', { lender_name: lenderName, product_name: productName }),

    /**
     * Track when a comparison is duplicated.
     */
    duplicateComparison: () => trackScenarios('duplicate_comparison'),

    /**
     * Track when the comparison product limit is reached.
     */
    productLimitReached: () => trackScenarios('product_limit_reached'),

    /**
     * Track when a comparison is loaded.
     */
    loadComparison: () => trackScenarios('load_comparison'),

    /**
     * Track when a comparison is previewed and shared.
     */
    previewAndShareComparison: () => trackScenarios('preview_and_share_comparison'),

    /**
     * Track when a comparison is published.
     * @param {'Comparison Management'|'Scenario Preview'} page
     */
    publishComparison: (page) => trackScenarios('publish_comparison', { page }),

    /**
     * Tracks when all products are removed from a comparison.
     */
    removeAllProducts: () => trackScenarios('remove_all_products'),

    /**
     * Track when user attempts to remove the product containing the base scenario.
     * @param {String} lenderName
     * @param {String} productName
     */
    removeBaseScenarioWarning: (lenderName, productName) =>
      trackScenarios('remove_base_scenario_warning', { lender_name: lenderName, product_name: productName }),

    /**
     * Track when a product is removed from a comparison.
     * @param {String} lenderName
     * @param {String} productName
     */
    removeProduct: (lenderName, productName) =>
      trackScenarios('remove_product', { lender_name: lenderName, product_name: productName }),

    /**
     * Track when a borrower summary is saved on a comparison.
     */
    saveBorrowerSummary: () => trackScenarios('save_borrower_summary'),

    /**
     * Track when a comparison is saved.
     */
    saveComparison: () => trackScenarios('save_comparison'),

    /**
     * Track when a comparison is unpublished.
     * @param {'Comparison Management'|'Scenario Preview'} page
     */
    unpublishComparison: (page) => trackScenarios('unpublish_comparison', { page }),
  };

  // Upgrade Events
  upgrade = {
    /**
     * Track clicks on the upgrade basic user banner close button.
     * @param {String} bannerType the type of upgrade banner
     */
    bannerHide: (bannerType) => trackUpgrade('banner_hide', { banner_type: bannerType }),

    /**
     * Track clicks on the upgrade basic user banner link.
     * @param {String} bannerType the type of upgrade banner
     * @param {String} page the destination page of the link
     */
    bannerLinkClick: (bannerType, page) => () => trackUpgrade('banner_link_click', { banner_type: bannerType, page }),

    /**
     * Tracks clicks on the Chat With Us top bar button.
     */
    chatWithUsButtonClick: () => trackUpgrade('chat_with_us_button_click'),

    /**
     * Track clicks on upgrade badges and links.
     * @param {String} target The target component associated with the badge/link
     */
    click: (target) => () => trackUpgrade('click', { target }),
  };
}

/* Helper functions */

/**
 * Helper function for emitting analytic events.
 * @param {String} area
 * @return {(action: String, attributes: Object) => void}
 */
const track =
  (area) =>
  (action, attributes = {}) => {
    if (!window.__INITIALSTATE__.user.features.enable_analytics) return;

    gtag('event', `${area}_${action}`, {
      category: area,
      ...attributes,
    });
  };

/* Wrapper functions for setting area/event categories */
const trackAiAssistant = track('ai_assistant');
const trackAltLending = track('alt_lending');
const trackBilling = track('billing');
const trackCompare = track('compare');
const trackCompareMenu = track('compare_menu');
const trackLenderDetails = track('lender_details');
const trackPolicy = track('policy');
const trackProductSearch = track('product_search');
const trackPromotions = track('promotions');
const trackScenarios = track('scenarios');
const trackUpgrade = track('upgrade');

/**
 * Track changes to rate filters.
 * @param {Object} filter
 */
const trackFilter = (filter) => trackProductSearch('filter_change', filter);

/**
 * @param {'desktop'|'mobile'} device
 * @return {(label: Object) => void}
 */
const trackNavigation = (device) => (page) => () => track('navigation')(`${device}_link_clicked`, { page });

export default new Analytics();
