import React from 'react';
import classnames from 'classnames';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useResponsiveWidth } from 'libs';

const Toast = () => {
  const { isMinSm } = useResponsiveWidth();
  return (
    <ToastContainer
      autoClose={4000}
      bodyClassName="fw-bold"
      className={classnames({ 'w-auto': isMinSm })}
      closeButton={false}
      hideProgressBar
      position="bottom-center"
      theme="colored"
      transition={Slide}
    />
  );
};

export default Toast;
