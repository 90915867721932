import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { analytics, useResponsiveWidth } from 'libs';
import { Hero } from 'components/common';

const CompareHeader = ({ onCreateReportClick }) => {
  const history = useHistory();

  const { isMinMd } = useResponsiveWidth();

  const handleClick = () => {
    analytics.compare.backButtonClick();
    return history.location.state?.from === 'rates' ? history.goBack() : history.replace('/rates');
  };

  return (
    <Hero fixed>
      {isMinMd && (
        <Hero.Image>
          <img src="/images/spotlight-logo-200x200.png" alt="lenderspotlight logo" />
        </Hero.Image>
      )}
      <Hero.Body>
        <div className="compare-header">
          <div>
            <Link to="/rates">Rates</Link>
            {' / Compare'}
          </div>
        </div>
        <div className="compare-header__title">Compare</div>
      </Hero.Body>
      <div>
        <button onClick={handleClick} className="compare-header__backlink me-3" type="button">
          &lt; Go Back
        </button>
        <button
          className="compare-header__create-report-btn"
          data-testid="compare-open-modal-button"
          onClick={onCreateReportClick}
          type="button"
        >
          <CreateReportIcon className="icon compare-header__icon" />
          Create Report
        </button>
      </div>
    </Hero>
  );
};

CompareHeader.propTypes = {
  onCreateReportClick: PropTypes.func.isRequired,
};

const CreateReportIcon = () => (
  <svg
    className="icon compare-header__icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="18"
    height="18"
  >
    <title>create report</title>
    <g
      className="nc-icon-wrapper"
      strokeLinecap="square"
      strokeLinejoin="miter"
      strokeWidth="1"
      transform="translate(0.5 0.5)"
      fill="#FFFFFF"
      stroke="#FFFFFF"
    >
      <line x1="7" y1="7" x2="8" y2="7" fill="none" stroke="#FFFFFF" strokeMiterlimit="10" />
      <line x1="12" y1="7" x2="17" y2="7" fill="none" stroke="#FFFFFF" strokeMiterlimit="10" />
      <line x1="7" y1="12" x2="8" y2="12" fill="none" stroke="#FFFFFF" strokeMiterlimit="10" />
      <line x1="12" y1="12" x2="17" y2="12" fill="none" stroke="#FFFFFF" strokeMiterlimit="10" />
      <line x1="7" y1="17" x2="12" y2="17" fill="none" stroke="#FFFFFF" strokeMiterlimit="10" />
      <polyline points="13 23 2 23 2 1 22 1 22 13" fill="none" stroke="#FFFFFF" strokeMiterlimit="10" />
      <line x1="20" y1="17" x2="20" y2="23" fill="none" strokeMiterlimit="10" data-color="color-2" />
      <line x1="17" y1="20" x2="23" y2="20" fill="none" strokeMiterlimit="10" data-color="color-2" />
    </g>
  </svg>
);

export default CompareHeader;
