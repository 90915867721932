import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'state';
import { Button, FloatingTextbox, Icon } from 'components/common';

const CurrentFilters = ({ onClear, showSaveFiltersButton = true }) => {
  const hasFilters = useSelector((state) => selectors.filters.hasFilters(state));
  const isFiltersPanelOpen = useSelector((state) => selectors.searches.isFiltersPanelOpen(state));
  const dispatch = useDispatch();
  const toggleFiltersPanel = () => dispatch(actions.searches.toggleFiltersPanel());

  return (
    <div className="align-items-center cursor-pointer d-flex filter filter--current justify-content-between">
      <Button disabled={!hasFilters} onClick={onClear} testId="filter-clear" variant="primary-dark" width="block">
        Clear Filters
      </Button>
      {showSaveFiltersButton && (
        <FloatingTextbox top="1.8rem" text="Saved&nbsp;Filters">
          <button
            className="btn btn-link btn-link__inline btn-reset"
            data-testid="filter-save"
            onClick={toggleFiltersPanel}
            style={{ pointerEvents: isFiltersPanelOpen ? 'none' : 'auto' }}
            type="button"
          >
            <Icon className="ms-3 mt-1 text-1_5" name="floppy-disk" />
          </button>
        </FloatingTextbox>
      )}
    </div>
  );
};

CurrentFilters.propTypes = {
  onClear: PropTypes.func.isRequired,
  showSaveFiltersButton: PropTypes.bool,
};

export default CurrentFilters;
