import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { actions, selectors } from 'state';
import { analytics, ratesFilter, useOnClickOutside } from 'libs';
import { Button, MenuButton, Panel } from 'components/common';
import { SavedFilter } from 'components/navigation';

const SavedFilterPanel = ({ dispatch, isFiltersPanelOpen, isLoading, searches }) => {
  const history = useHistory();
  const location = useLocation();

  const buttonRef = useRef();
  const panelRef = useRef();

  const [name, setName] = useState('');

  const onClose = useCallback(() => {
    if (isLoading || !isFiltersPanelOpen) return;
    dispatch.searches.toggleFiltersPanel();
    analytics.productSearch.savedFilters.close();
  }, [dispatch.searches, isLoading, isFiltersPanelOpen]);

  useOnClickOutside(onClose, [buttonRef, panelRef]);

  const onClick = () => {
    if (isFiltersPanelOpen) {
      onClose();
    } else {
      dispatch.searches.toggleFiltersPanel();
      analytics.productSearch.savedFilters.open();
    }
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onRemove = (searchId) => {
    dispatch.searches.remove(searchId);
  };

  const onSave = () => {
    dispatch.searches.create({
      name,
      search: location.search,
    });

    setName('');
  };

  const onSearch = (event) => {
    const search = ratesFilter.parse(event.target.getAttribute('data-search'));

    history.push({
      pathname: location.pathname,
      search: ratesFilter.stringify(search),
    });
  };

  return (
    <>
      <MenuButton
        active={isFiltersPanelOpen}
        icon={
          <BookmarkIcon
            className={classnames('saved-filters__icon', {
              'saved-filters__icon--active': isFiltersPanelOpen,
            })}
          />
        }
        ref={buttonRef}
        onClick={onClick}
        testId="saved-filter-button"
        text="Saved Filters"
      />
      <Panel isOpen={isFiltersPanelOpen} ref={panelRef} testId="saved-filter-panel">
        <Panel.Header equalXPadding onClose={onClose}>
          <div className="ms-auto text-1_1875">Saved Filters</div>
        </Panel.Header>
        <Panel.Body>
          <div className="saved-filters-form">
            <h3 className="saved-filters__heading">Add Saved Filter</h3>
            <input
              className="form-control mb-2"
              data-testid="saved-filter-name-field"
              onChange={onNameChange}
              type="text"
              placeholder="Name"
              value={name}
            />
            <Button
              disabled={isLoading || !name}
              onClick={onSave}
              testId="saved-filter-save-button"
              variant="primary-light"
              width="block"
            >
              Save
            </Button>
          </div>
          <h3 className="saved-filters__heading">Saved Filtersa</h3>
          {searches.map((search) => (
            <SavedFilter key={`search-${search.id}`} search={search} onClick={onSearch} onRemove={onRemove} />
          ))}
        </Panel.Body>
      </Panel>
    </>
  );
};

SavedFilterPanel.propTypes = {
  dispatch: PropTypes.shape({
    products: actions.products.propTypes,
    searches: actions.searches.propTypes,
  }).isRequired,
  isFiltersPanelOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      search: PropTypes.string,
    })
  ).isRequired,
};

const BookmarkIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
    <path fill="none" stroke="#757575" d="M8.994 19.376L17 23V5a4 4 0 00-4-4H5a4 4 0 00-4 4v18l7.994-3.624z" />
  </svg>
);

BookmarkIcon.propTypes = {
  className: PropTypes.string.isRequired,
};

const mapState = (state) => ({
  isFiltersPanelOpen: selectors.searches.isFiltersPanelOpen(state),
  isLoading: selectors.searches.isLoading(state),
  searches: selectors.searches.getAll(state),
});

const mapDispatch = (dispatch) => ({
  dispatch: {
    filters: bindActionCreators(actions.filters, dispatch),
    products: bindActionCreators(actions.products, dispatch),
    searches: bindActionCreators(actions.searches, dispatch),
  },
});

export default connect(mapState, mapDispatch)(SavedFilterPanel);
