import * as Sentry from '@sentry/react';

/**
 * Delete data on an API endpoint.
 * @param {String} endpoint
 * @param {Object|null} data
 * @param {Object|null} config Axios config
 * @return {Promise}
 */
const destroy = async (endpoint, data = null, config = null) => {
  try {
    const response = await axios.delete(`/api/${endpoint}`, data, config);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

/**
 * Fetch data from an API endpoint.
 * @param {String} endpoint
 * @param {Object|null} [params=null]
 * @return {Promise}
 */
const get = async (endpoint, params = null) => {
  try {
    const response = await axios.get(`/api/${endpoint}`, { params });
    return response;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

/**
 * Upadate data to an API endpoint.
 * @param {String} endpoint
 * @param {Object|null} data
 * @param {Object|null} config Axios config
 * @return {Promise}
 */
const patch = async (endpoint, data = null, config = null) => {
  try {
    const response = await axios.patch(`/api/${endpoint}`, data, config);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

/**
 * Send data to an API endpoint.
 * @param {String} endpoint
 * @param {Object|null} data
 * @param {Object|null} config Axios config
 * @return {Promise}
 */
const post = async (endpoint, data = null, config = null) => {
  try {
    const response = await axios.post(`/api/${endpoint}`, data, config);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

/**
 * Update data on an API endpoint.
 * @param {String} endpoint
 * @param {Object|null} data
 * @param {Object|null} config Axios config
 * @return {Promise}
 */
const put = async (endpoint, data = null, config = null) => {
  try {
    const response = await axios.put(`/api/${endpoint}`, data, config);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export default {
  destroy,
  get,
  patch,
  post,
  put,
};
