import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'libs';

const VARIANT_BORDER = 'border';
const VARIANT_BORDER_DANGER = 'border-danger';
const VARIANT_DANGER = 'danger';
const VARIANT_DEFAULT = 'default';

const VARIANT_LINK_INLINE = 'link-inline';
const VARIANT_LINK_INLINE_RED = 'link-inline-red';
const VARIANT_LINK_INLINE_STRONG = 'link-inline-strong';

const VARIANT_PRIMARY_DARK = 'primary-dark';
const VARIANT_PRIMARY_LIGHT = 'primary-light';

const SIZE_DEFAULT = 'default';
const SIZE_LG = 'lg';

const TEXT_DEFAULT = 'default';
const TEXT_BOLD = 'bold';

const WIDTH_CONTENT = 'content';
const WIDTH_BLOCK = 'block';

const Button = forwardRef(
  (
    {
      children,
      disabled = false,
      onClick = noop,
      size = SIZE_DEFAULT,
      submit = false,
      testId,
      text = TEXT_DEFAULT,
      variant = VARIANT_DEFAULT,
      width = WIDTH_CONTENT,
    },
    ref
  ) => (
    <button
      className={classnames(
        'btn btn-reset',
        getVariantClassName(variant),
        getSizeClassName(size),
        getTextClassName(text),
        getWidthClassName(width),
        disabled && getDisabledClassName(variant)
      )}
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      ref={ref}
      type={submit ? 'submit' : 'button'}
    >
      {children}
    </button>
  )
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([SIZE_DEFAULT, SIZE_LG]),
  submit: PropTypes.bool,
  testId: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.oneOf([
    VARIANT_BORDER,
    VARIANT_BORDER_DANGER,
    VARIANT_DANGER,
    VARIANT_DEFAULT,
    VARIANT_LINK_INLINE,
    VARIANT_LINK_INLINE_RED,
    VARIANT_LINK_INLINE_STRONG,
    VARIANT_PRIMARY_DARK,
    VARIANT_PRIMARY_LIGHT,
  ]),
  width: PropTypes.oneOf([WIDTH_CONTENT, WIDTH_BLOCK]),
};

/**
 * @param {String} width
 * @return {(String|Null)}
 */
const getWidthClassName = (width) => (width === WIDTH_BLOCK ? 'btn-block' : null);

/**
 * @param {String} size
 * @return {(String|Null)}
 */
const getSizeClassName = (size) => (size === SIZE_LG ? 'btn-lg' : null);

/**
 * @param {String} text
 * @return {(String|Null)}
 */
const getTextClassName = (text) => (text === TEXT_BOLD ? 'fw-bold' : null);

/**
 * @param {String} variant
 * @return {(String|Null)} String is returned if a special variant, otherwise `null`
 */
const getVariantClassName = (variant) => {
  switch (variant) {
    case VARIANT_BORDER:
      return 'btn-outline-primary';
    case VARIANT_BORDER_DANGER:
      return 'btn-outline-danger';
    case VARIANT_DANGER:
      return 'btn-danger';
    case VARIANT_LINK_INLINE:
      return 'btn-link btn-link__inline';
    case VARIANT_LINK_INLINE_RED:
      return 'btn-link btn-link__inline text-red';
    case VARIANT_LINK_INLINE_STRONG:
      return 'btn-link btn-link__inline-strong';
    case VARIANT_PRIMARY_DARK:
      return 'btn-primary-dark disabled:bg-border-gray disabled:border-border-gray opacity-100';
    case VARIANT_PRIMARY_LIGHT:
      return 'btn-primary';
    case VARIANT_DEFAULT:
    // Fallthrough
    default:
      return null;
  }
};

/**
 * @param {Boolean} disabled
 * @return {(String|Null)}
 */
const getDisabledClassName = (variant) => {
  switch (variant) {
    case VARIANT_BORDER:
      return 'disabled:border-heading-gray disabled:text-heading-gray';
    case VARIANT_PRIMARY_DARK:
    case VARIANT_PRIMARY_LIGHT:
    case VARIANT_DANGER:
      return 'btn-disabled';
    default:
      return null;
  }
};

export default Button;
