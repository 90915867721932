import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'components/common';

const ConfirmationModal = ({
  cancelButtonText = 'Cancel',
  children,
  confirmButtonText = 'Confirm',
  handleConfirmation,
  hideModal,
  isConfirmDangerous = false,
  showModal,
  testId,
  title,
}) => (
  <Modal onHide={hideModal} show={showModal} centered>
    <div className="p-3">
      <h4 className="fw-bold" data-testid={`${testId}-modal-title`}>
        {title}
      </h4>
      <div className="my-4">{children}</div>
      <div className="d-flex">
        <Button
          onClick={handleConfirmation}
          onHideModal={hideModal}
          testId={`${testId}-confirm-modal-button`}
          variant={isConfirmDangerous ? 'danger' : 'primary-dark'}
        >
          {confirmButtonText}
        </Button>
        <div className="ms-2">
          <Button onClick={hideModal} testId={`${testId}-cancel-modal-button`} variant="border">
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </div>
  </Modal>
);

ConfirmationModal.propTypes = {
  cancelButtonText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  confirmButtonText: PropTypes.string,
  handleConfirmation: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isConfirmDangerous: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  testId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ConfirmationModal;
