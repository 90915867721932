import { createSelector } from 'reselect';

/**
 * Get All Searches from state.
 * @param {Object} state
 * @return {Object}
 */
const getAllSearches = (state) => state.searches.byId;

/**
 * Get All Searches.
 * @param {Object} state
 * @return {Array}
 */
export const getAll = createSelector([getAllSearches], (searches) =>
  Object.keys(searches).map((searchId) => searches[searchId])
);

/**
 * Get a Search by Id.
 * @param  {Object} state
 * @param  {String|Number} id
 * @return {Object}
 */
export const getById = (state, id) => state.searches.byId[id];

/**
 * Get the filter panel state.
 * @param  {Object} state
 * @return {Boolean}
 */
export const isFiltersPanelOpen = (state) => state.searches.isFiltersPanelOpen;

/**
 * Get the current loading state.
 * @param  {Object} state
 * @return {Boolean}
 */
export const isLoading = (state) => state.searches.isLoading;
