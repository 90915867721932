import * as brokerages from './brokerages';
import * as contacts from './contacts';
import * as filters from './filters';
import * as guidelines from './guidelines';
import * as invitations from './invitations';
import * as lenders from './lenders';
import * as lendersByProducts from './lendersByProducts';
import * as networks from './networks';
import * as notifications from './notifications';
import * as policies from './policies';
import * as products from './products';
import * as programs from './programs';
import * as regions from './regions';
import * as searches from './searches';
import * as settings from './settings';
import * as users from './users';

export default {
  brokerages,
  contacts,
  filters,
  guidelines,
  invitations,
  lenders,
  lendersByProducts,
  networks,
  notifications,
  policies,
  products,
  programs,
  regions,
  searches,
  settings,
  users,
};
