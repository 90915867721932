/**
 * Calculate the balance remaining after the current term.
 * @param {Number} compoundingRate
 * @param {Number} loanAmount
 * @param {Number} mortgagePayment
 * @param {String} paymentFrequency
 * @param {Number} termPayments
 * @return {Number}
 */
const calculateBalanceRemaining = (rate, loanAmount, mortgagePayment, paymentFrequency, termPayments) => {
  const compoundingRate = calculatePeriodicRate(rate / 100, paymentFrequency);
  return (
    loanAmount * (1 + compoundingRate) ** termPayments -
    mortgagePayment * (((1 + compoundingRate) ** termPayments - 1) / compoundingRate)
  );
};

/**
 * Calculates the borrowing amount from the purchase price and down payment.
 * @param {Number} purchasePrice
 * @param {Number} downPayment
 * @return {Number}
 */
const calculateBorrowingAmount = (purchasePrice, downPayment) => purchasePrice - downPayment;

/**
 * Calculates the insurance amount based on the purchase price, down payment, and insurability of the product.
 * @param {Number} borrowingAmount
 * @param {String} insurable
 * @param {String} insurance
 * @param {Boolean} insuranceActive
 * @param {Number} ltv
 * @param {Boolean} refinanceOnly
 * @return {Number}
 */
const calculateInsuranceAmount = (borrowingAmount, insurable, insurance, insuranceActive, ltv, refinanceOnly) => {
  switch (true) {
    case insurable === 'No':
    case insurance === 'Conventional' && !insuranceActive:
    case refinanceOnly:
    case ltv > 95:
      return 0;
    case ltv > 90:
      return 0.04 * borrowingAmount;
    case ltv > 85:
      return 0.031 * borrowingAmount;
    case ltv > 80:
      return 0.028 * borrowingAmount;
    case ltv > 75:
      return 0.024 * borrowingAmount;
    case ltv > 65:
      return 0.017 * borrowingAmount;
    default:
      return 0.006 * borrowingAmount;
  }
};

/**
 * Determines whether a scenario is eligible for insurance.
 * @param {String} insurable
 * @param {String} insurance
 * @param {Number} ltv
 * @param {Boolean} refinanceOnly
 * @returns {String}
 */
const calculateinsuranceStatus = (insurable, insurance, ltv, refinanceOnly) => {
  switch (true) {
    case insurable === 'No':
    case refinanceOnly:
    case ltv > 95:
      return 'uninsurable';
    case insurance === 'Insured':
      return 'insured';
    default:
      return 'insurable';
  }
};

/**
 * Calculates the borrowing amount and adds insurance premiums if applicable.
 * @param {Number} borrowingAmount
 * @param {Number} insuranceAmount
 * @return {Number}
 */
const calculateLoanAmount = (borrowingAmount, insuranceAmount) => borrowingAmount + insuranceAmount;

/**
 * Calculates the LTV from the mortgage amount and property value.
 * @param {Number} mortgageAmount
 * @param {Number} propertyValue
 * @return {Number}
 */
const calculateLtv = (mortgageAmount, propertyValue) => (mortgageAmount / propertyValue) * 100;

/**
 * Calculates the maximum monthly payment from annual income and GDS.
 * @param {Number} annualIncome
 * @param {Number} gds
 * @return {Number}
 */
const calculateMaxMonthlyPayment = (annualIncome = 0, gds = 39) => (annualIncome * (gds / 100)) / 12;

/**
 * Calculates the mortgage payment from the borrowing amount and present value factor.
 * @param {Number} maxMonthlyPayment
 * @param {Number} loanPayments
 * @param {Number} creditCardBalance
 * @return {Number}
 */
const calculateMonthlyPayment = (loanPayments = 0, maxMonthlyPayment = 0, creditCardBalance = 0) =>
  maxMonthlyPayment - loanPayments - creditCardBalance * 0.03;

/**
 * Calculates the mortgage payment from the amortization, loan amount, rate, and payment frequency.
 * @param {Number} amortization
 * @param {Number} loanAmount
 * @param {String} paymentFrequency
 * @param {Number} rate
 * @return {Number}
 */
const calculateMortgagePayment = (amortization, loanAmount, paymentFrequency, rate) => {
  const monthlyRate = calculatePeriodicRate(rate / 100, 'Monthly');
  const monthlyPayments = getTotalPayments('Monthly', amortization);
  const monthlyPayment = (loanAmount * monthlyRate) / (1 - (1 + monthlyRate) ** -monthlyPayments);
  const periodicRate = calculatePeriodicRate(rate / 100, paymentFrequency);
  const totalPayments = getTotalPayments(paymentFrequency, amortization);
  switch (paymentFrequency) {
    case 'Accelerated bi-weekly':
      return monthlyPayment / 2;
    case 'Accelerated weekly':
      return monthlyPayment / 4;
    default:
      return (loanAmount * periodicRate) / (1 - (1 + periodicRate) ** -totalPayments);
  }
};

/**
 * Calculates total mortgage affordability from annual income and qualifying rate.
 * @param {Number} annualIncome
 * @param {Number} qualifyingRate
 * @param {Number} loanPayments
 * @param {Number} creditCardBalance
 * @param {Number} amortization
 * @param {Number} gds
 * @return {Number}
 */
const calculateTotalMortgageAffordability = (
  annualIncome = 0,
  qualifyingRate = 0,
  loanPayments = 0,
  creditCardBalance = 0,
  amortization = 30,
  gds = 39
) => {
  if (!annualIncome || !qualifyingRate) {
    return 0;
  }

  const compoundQualifyingRate = calculatePeriodicRate(qualifyingRate / 100, 'Monthly');
  const presentValueFactor = calculatePresentValueFactor(compoundQualifyingRate, amortization);
  const presentValueInterestFactorOfAnnuity = calculatePresentValueInterestFactorOfAnnuity(
    presentValueFactor,
    compoundQualifyingRate
  );
  const maxMonthlyPayment = calculateMaxMonthlyPayment(annualIncome, gds);
  const monthlyPayment = calculateMonthlyPayment(loanPayments, maxMonthlyPayment, creditCardBalance);
  return monthlyPayment * presentValueInterestFactorOfAnnuity;
};

/**
 * Calculates the total sum of a specific property for a list of borrowers.
 *
 * @param {Array<Object>} borrowers
 * @param {String} propertyName
 * @return {Number}
 */
const calculateBorrowersTotal = (borrowers = [], propertyName) =>
  borrowers.reduce((total, borrower) => total + (parseFloat(borrower[propertyName]) || 0), 0);

/**
 * Calculates interest paid over a term.
 * @param {Number} mortgagePayment
 * @param {String} paymentFrequency
 * @param {String} term
 * @param {Number} termPrincipal
 * @return {Number}
 */
const calculateTermInterest = (mortgagePayment, paymentFrequency, term, termPrincipal) => {
  const termPayments = getTermPayments(paymentFrequency, term);
  return mortgagePayment * termPayments - termPrincipal;
};

/**
 * Calculates the principal paid over a term.
 * @param {Number} balanceRemaining
 * @param {Number} loanAmount
 * @return {Number}
 */
const calculateTermPrincipal = (balanceRemaining, loanAmount) => loanAmount - balanceRemaining;

/**
 * Computes all mortgage details for a scenario from product and scenario parameters.
 * @param {String} amortization
 * @param {Number} downPayment
 * @param {String} insurable
 * @param {Number} purchasePrice
 * @param {String} paymentFrequency
 * @param {Number} rate
 * @param {Boolean} refinanceOnly
 * @param {String} term
 * @return {Object}
 */
const calculateScenarioDetails = (
  amortization,
  downPayment,
  insurable,
  insurance,
  insuranceActive,
  paymentFrequency,
  purchasePrice,
  rate,
  refinanceOnly,
  term
) => {
  const borrowingAmount = calculateBorrowingAmount(purchasePrice, downPayment);
  const ltv = calculateLtv(borrowingAmount, purchasePrice);
  const insuranceAmount = calculateInsuranceAmount(
    borrowingAmount,
    insurable,
    insurance,
    insuranceActive,
    ltv,
    refinanceOnly
  );
  const insuranceStatus = calculateinsuranceStatus(insurable, insurance, ltv, refinanceOnly);
  const loanAmount = calculateLoanAmount(borrowingAmount, insuranceAmount);
  const termPayments = getTermPayments(paymentFrequency, term);
  const mortgagePayment = calculateMortgagePayment(amortization, loanAmount, paymentFrequency, rate);
  const balanceRemaining = calculateBalanceRemaining(rate, loanAmount, mortgagePayment, paymentFrequency, termPayments);
  const termPrincipal = calculateTermPrincipal(balanceRemaining, loanAmount);
  const termInterest = calculateTermInterest(mortgagePayment, paymentFrequency, term, termPrincipal);
  return {
    balanceRemaining,
    borrowingAmount,
    insuranceAmount,
    insuranceStatus,
    loanAmount,
    mortgagePayment,
    termInterest,
    termPrincipal,
  };
};

// Local helper functions

/**
 * Calculates the effective rate from the nominal rate.
 * @param {Number} nominalRate
 * @return {Number}
 */
const calculateEffectiveRate = (nominalRate, compoundingFrequency = 2) =>
  (nominalRate / compoundingFrequency + 1) ** compoundingFrequency - 1;

/**
 * Calculates the effective periodic rate from the nominal rate.
 * @param {Number} nominalRate
 * @param {String} paymentFrequency
 * @return {Number}
 */
const calculatePeriodicRate = (nominalRate, paymentFrequency) => {
  switch (paymentFrequency) {
    case 'Monthly':
      return (1 + calculateEffectiveRate(nominalRate)) ** (1 / 12) - 1;
    case 'Semi-monthly':
      return (1 + calculateEffectiveRate(nominalRate)) ** (1 / 24) - 1;
    case 'Bi-weekly':
    case 'Accelerated bi-weekly':
      return (1 + calculateEffectiveRate(nominalRate)) ** (1 / 26) - 1;
    case 'Weekly':
    case 'Accelerated weekly':
      return (1 + calculateEffectiveRate(nominalRate)) ** (1 / 52) - 1;
    default:
      return 0;
  }
};

/**
 * Calculates the present value factor from the rate and amortization in years.
 * @param {Number} rate
 * @param {Numer} amortization
 * @return {Number}
 */
const calculatePresentValueFactor = (rate, amortization) => 1 / (1 + rate) ** (amortization * 12);

/**
 * Calculates the present value interest factor of annuity.
 * @param {Number} presentValueFactor
 * @param {Number} rate
 * @return {Number}
 */
const calculatePresentValueInterestFactorOfAnnuity = (presentValueFactor, rate) => (1 - presentValueFactor) / rate;

/**
 * Get the number of months for a given term.
 * @param {String} term
 * @return {Number}
 */
const getTermInMonths = (term) => {
  switch (term) {
    case '6 Months':
      return 6;
    case '1 Year':
      return 12;
    case '2 Year':
      return 24;
    case '3 Year':
      return 36;
    case '4 Year':
      return 48;
    case '5 Year':
      return 60;
    case '6 Year':
      return 72;
    case '7 Year':
      return 84;
    case '10 Year':
      return 120;
    default:
      return 0;
  }
};

/**
 * Get the total number of payments for a given payment frequency and term.
 * @param {String} paymentFrequency
 * @param {String} term
 * @return {Number}
 */
const getTermPayments = (paymentFrequency, term) => {
  const termInMonths = getTermInMonths(term);
  switch (paymentFrequency) {
    case 'Monthly':
      return termInMonths;
    case 'Semi-monthly':
      return termInMonths * 2;
    case 'Bi-weekly':
      return termInMonths * (26 / 12);
    case 'Accelerated bi-weekly':
      return termInMonths * (26 / 12);
    case 'Weekly':
      return termInMonths * (52 / 12);
    case 'Accelerated weekly':
      return termInMonths * (52 / 12);
    default:
      return 0;
  }
};

/**
 * Gets the total number of payments for a given payment frequency and amortization.
 * @param {String} paymentFrequency
 * @param {Number} amortization
 * @returns {Number}
 */
const getTotalPayments = (paymentFrequency, amortization) => {
  switch (paymentFrequency) {
    case 'Monthly':
      return amortization * 12;
    case 'Semi-monthly':
      return amortization * 24;
    case 'Bi-weekly':
    case 'Accelerated bi-weekly':
      return amortization * 26;
    case 'Weekly':
    case 'Accelerated weekly':
      return amortization * 52;
    default:
      return 0;
  }
};

export default {
  borrowersDetailsTotal: calculateBorrowersTotal,
  borrowingAmount: calculateBorrowingAmount,
  ltv: calculateLtv,
  maxMonthlyPayment: calculateMaxMonthlyPayment,
  scenarioDetails: calculateScenarioDetails,
  totalMortgageAffordability: calculateTotalMortgageAffordability,
};
