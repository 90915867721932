import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'libs';
import * as Filter from 'components/filters';

const FiltersAside = ({
  disableLenderFilter,
  filters: {
    amortization,
    beacon,
    gds,
    insurable,
    insurance,
    lender_type,
    lenders,
    ltv,
    mortgage_amount,
    programs,
    property_value,
    purchase,
    purpose,
    rate_hold,
    refinance,
    regions,
    tds,
    term_id,
    transfer,
    type,
  },
  inputSize = 'default',
  onClear = noop,
  onFilter = noop,
  showClearButton = true,
  showSaveFiltersButton = true,
}) => (
  <div className="sidebar z-2" id="filters">
    {showClearButton && <Filter.CurrentFilters onClear={onClear} showSaveFiltersButton={showSaveFiltersButton} />}
    <Filter.Region filters={{ regions }} isPro onFilter={onFilter} />
    <Filter.TransactionType filters={{ refinance, purchase, transfer }} onFilter={onFilter} />
    <Filter.PurposeAndRateType filters={{ purpose, programs }} isPro onFilter={onFilter} />
    <Filter.TermType filters={{ type }} onFilter={onFilter} />
    <Filter.Term filters={{ term_id }} onFilter={onFilter} size={inputSize} />
    <Filter.Amortization filters={{ amortization }} onFilter={onFilter} />
    <Filter.LTVCalculation filters={{ ltv, mortgage_amount, property_value }} onFilter={onFilter} />
    <Filter.GdsTds filters={{ gds, tds }} isPro onFilter={onFilter} />
    <Filter.Insurance filters={{ insurance, insurable }} isPro onFilter={onFilter} />
    <Filter.Lender disabled={disableLenderFilter} filters={{ lenders }} onFilter={onFilter} />
    <Filter.LenderType filters={{ lender_type }} onFilter={onFilter} />
    <Filter.Beacon filters={{ beacon }} isPro onFilter={onFilter} />
    <Filter.RateHold filters={{ rate_hold }} isPro onFilter={onFilter} />
    <Filter.Program filters={{ programs }} isPro onFilter={onFilter} size={inputSize} />
  </div>
);

FiltersAside.propTypes = {
  disableLenderFilter: PropTypes.bool.isRequired,
  filters: Filter.FiltersPropTypes.isRequired,
  inputSize: PropTypes.oneOf(['default', 'lg']),
  onClear: PropTypes.func,
  onFilter: PropTypes.func,
  showClearButton: PropTypes.bool,
  showSaveFiltersButton: PropTypes.bool,
};

export default FiltersAside;
