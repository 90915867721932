import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import numeral from 'numeral';
import { analytics, calculate } from 'libs';
import { LockIcon } from 'icons';
import Filter from './Filter';
import Text from './Text';

/**
 * Separate debounce trackers are required so each field change is tracked.
 * If the same debounce tracker is used for all fields, then earlier events
 * that belong to one field may be ignored when another field changes
 * within the debounce period.
 *
 * The debounce duration matches `analytics.productSearch.filterDebounced`.
 */
const trackLTVChange = debounce((value) => analytics.productSearch.filter({ ltv: value || 'cleared' }), 400);
const trackMortgageAmountChange = debounce(
  (value) => analytics.productSearch.filter({ mortgage_amount: value || 'cleared' }),
  400
);
const trackPropertyValueChange = debounce(
  (value) => analytics.productSearch.filter({ property_value: value || 'cleared' }),
  400
);

const LTVCalculationFilter = ({ filters, onFilter }) => {
  const { ltv: initialLTV, mortgage_amount: initialMortgageAmount, property_value: initialPropertyValue } = filters;

  const [ltv, setLTV] = useState(initialLTV);
  const [mortgageAmount, setMortgageAmount] = useState(initialMortgageAmount);
  const [propertyValue, setPropertyValue] = useState(initialPropertyValue);

  useEffect(() => {
    const hasNewFilters =
      filters.ltv !== ltv || filters.mortgage_amount !== mortgageAmount || filters.property_value !== propertyValue;

    if (hasNewFilters) {
      setLTV(filters.ltv);
      setMortgageAmount(filters.mortgage_amount);
      setPropertyValue(filters.property_value);
    }
  }, [filters, ltv, mortgageAmount, propertyValue]);

  const isLTVAutoCalculated = (newMortgageAmount, newPropertyValue) => Boolean(newMortgageAmount && newPropertyValue);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let newLTV = ltv;
    let newMortgageAmount = mortgageAmount;
    let newPropertyValue = propertyValue;

    switch (name) {
      case 'ltv':
        newLTV = numeral(value).value();
        trackLTVChange(newLTV);
        break;
      case 'mortgage_amount':
        newMortgageAmount = numeral(value).value();
        trackMortgageAmountChange(newMortgageAmount);
        break;
      case 'property_value':
        newPropertyValue = numeral(value).value();
        trackPropertyValueChange(newPropertyValue);
        break;
      default:
        break;
    }

    if (isLTVAutoCalculated(newMortgageAmount, newPropertyValue)) {
      newLTV = parseInt(calculate.ltv(newMortgageAmount, newPropertyValue), 10);
      trackLTVChange(newLTV);
    }

    setLTV(newLTV);
    setMortgageAmount(newMortgageAmount);
    setPropertyValue(newPropertyValue);

    onFilter({ ltv: newLTV, mortgage_amount: newMortgageAmount, property_value: newPropertyValue });
  };

  const formatValues = () => ({
    formattedLTV: ltv ? numeral(ltv).format() : '',
    formattedMortgageAmount: mortgageAmount ? numeral(mortgageAmount).format('$0,0') : '',
    formattedPropertyValue: propertyValue ? numeral(propertyValue).format('$0,0') : '',
  });

  const { formattedLTV, formattedMortgageAmount, formattedPropertyValue } = formatValues();
  const isCalculated = isLTVAutoCalculated(mortgageAmount, propertyValue);

  return (
    <>
      <Filter name="Mortgage Amount">
        <Text
          testId="filter-mortgage-amount"
          name="mortgage_amount"
          onChange={handleChange}
          placeholder="Enter mortgage amount"
          value={formattedMortgageAmount}
        />
      </Filter>
      <Filter name="Property Value">
        <Text
          testId="filter-property-value"
          name="property_value"
          onChange={handleChange}
          placeholder="Enter property value"
          value={formattedPropertyValue}
        />
      </Filter>
      <Filter name={`LTV${isCalculated ? ' (calculated)' : ''}`}>
        <div className="position-relative">
          <Text
            testId="filter-ltv"
            disabled={isCalculated}
            name="ltv"
            onChange={handleChange}
            placeholder="Enter LTV"
            value={formattedLTV}
          />
          {isCalculated && (
            <div className="position-absolute inset-y-0 right-0 d-flex flex-column justify-content-center px-2">
              <LockIcon className="w-6 opacity-80" />
            </div>
          )}
        </div>
      </Filter>
    </>
  );
};

LTVCalculationFilter.propTypes = {
  filters: PropTypes.shape({
    ltv: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    mortgage_amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    property_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default LTVCalculationFilter;
