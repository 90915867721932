// This library is an alternative to redux for accessing immutable global state data.
const { __INITIALSTATE__: data } = window;

/**
 * Helper for normalizing initial state data.
 * @param {Object} initialData
 * @return {Object}
 */
const normalizeData = (initialData) =>
  initialData?.reduce(
    (accum, type) => ({
      ...accum,
      [type.id]: {
        label: type.name,
        value: type.id,
      },
    }),
    {}
  );

// Alternative lending
const altLenders = normalizeData(data.alt_lenders);
const loanOptions = {
  commercial: normalizeData(data.commercial_loan_options ?? []),
  construction: normalizeData(data.construction_loan_options ?? []),
  residential: normalizeData(data.residential_loan_options ?? []),
};
const propertyTypes = {
  commercial: normalizeData(data.commercial_property_types ?? []),
  construction: normalizeData(data.construction_property_types ?? []),
  residential: normalizeData(data.residential_property_types ?? []),
};

const prices = {
  forUsers: data.prices?.filter((price) => price.for === 'users'),
  forProUsers: data.prices?.filter((price) => price.for === 'pro-users'),
};

/**
 * Retrieves normalized location data from initial state.
 * @var {Object[]}
 */
const locations = data.locations?.reduce(
  (accum, location) => ({
    ...accum,
    [location.id]: {
      label: location.name,
      region: location.region_id,
      value: location.id,
    },
  }),
  {}
);

/**
 * Retrieves normalized province data from initial state.
 * @var {Object[]}
 */
const provinces = data.regions
  ?.filter((region) => region.country_id === 'CA')
  .reduce(
    (accum, region) => ({
      ...accum,
      [region.id]: {
        label: region.name,
        value: region.id,
      },
    }),
    {}
  );

export default {
  altLenders,
  loanOptions,
  locations,
  prices,
  propertyTypes,
  provinces,
};
