import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    fallback: PropTypes.node,
    showDialog: PropTypes.bool,
  };

  static defaultProps = {
    fallback: null,
    showDialog: true,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  /**
   * We can fix certain errors by reloading. Passes reloaded flag to avoid infinite reload loops.
   * @param {*} error
   * @return {Boolean}
   */
  shouldRecoverByReload = (error) => error.name === 'ChunkLoadError' && !window.location.search.includes('reloaded');

  reloadOnce = () => {
    const { location } = window;
    location.href = `${location.origin}${location.pathname}${location.search || '?'}&reloaded`;
  };

  componentDidCatch(error, info) {
    if (this.shouldRecoverByReload(error)) {
      // Since we're reloading immediately we won't be in time to capture the exception
      this.reloadOnce();
    } else {
      Sentry.captureException(error, { extra: info });
    }

    if (this.props.showDialog) {
      Sentry.showReportDialog();
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      }

      return (
        <div style={{ padding: 20, textAlign: 'center' }}>
          <p>We&apos;re sorry — something&apos;s gone wrong. Our team has been notified.</p>
        </div>
      );
    }

    return this.props.children;
  }
}
