import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import { analytics } from 'libs';
import schema from 'state/schema';

const CREATE = 'lenderspotlight/searches/CREATE';
const CREATE_FAILED = 'lenderspotlight/searches/CREATE_FAILED';
const CREATE_SUCCESS = 'lenderspotlight/searches/CREATE_SUCCESS';
const DELETE = 'lenderspotlight/searches/DELETE';
const DELETE_FAILED = 'lenderspotlight/searches/DELETE_FAILED';
const FETCH = 'lenderspotlight/searches/FETCH';
const FETCH_BY_ID_SUCCESS = 'lenderspotlight/searches/FETCH_BY_ID_SUCCESS';
const FETCH_FAILED = 'lenderspotlight/searches/FETCH_FAILED';
const FETCH_SUCCESS = 'lenderspotlight/searches/FETCH_SUCCESS';
const TOGGLE_FILTERS_PANEL = 'lenderspotlight/searches/TOGGLE_FILTERS_PANEL';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  CREATE,
  CREATE_FAILED,
  CREATE_SUCCESS,
  DELETE,
  DELETE_FAILED,
  FETCH,
  FETCH_BY_ID_SUCCESS,
  FETCH_FAILED,
  FETCH_SUCCESS,
  TOGGLE_FILTERS_PANEL,
};

/**
 * PropTypes Validation
 * @type {Function}
 */
export const propTypes = PropTypes.shape({
  create: PropTypes.func,
  fetch: PropTypes.func,
  fetchById: PropTypes.func,
  remove: PropTypes.func,
  toggleFiltersPanel: PropTypes.func,
});

/**
 * Hydrate Initial State global LS object.
 * @type {Object}
 */
let initialState = {};

if (window.__INITIALSTATE__ && window.__INITIALSTATE__.user) {
  const data = normalize(window.__INITIALSTATE__.user, schema.user);
  initialState = data.entities.searches || {};
}

/**
 * ById Program Reducer
 * @param {Object} state
 * @return {Object}
 */
function byIdReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_SUCCESS:
    case FETCH_BY_ID_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.searches,
      };

    case DELETE:
      // A variable needs to be declared in this case to all
      // for the destructuring of the values that intended to
      // be removed.  Best immutable way of handling this... unless
      // you have a better solution?
      // eslint-disable-next-line no-case-declarations
      const { [action.payload]: omit, ...newState } = state;
      return newState;

    case FETCH_SUCCESS:
      return action.payload.entities.searches;

    default:
      return state;
  }
}

/**
 * Is Loading Reducer
 * @param  {Boolean} state
 * @param  {Objectq}  action
 * @return {Boolean}
 */
function isLoadingReducer(state = false, action) {
  switch (action.type) {
    case CREATE:
    case FETCH:
      return true;

    case CREATE_FAILED:
    case CREATE_SUCCESS:
    case FETCH_BY_ID_SUCCESS:
    case FETCH_FAILED:
    case FETCH_SUCCESS:
      return false;

    default:
      return state;
  }
}

/**
 * Is Filters Panel Open Reducer
 * @param  {Boolean} state
 * @param  {Object}  action
 * @return {Boolean}
 */
function isFiltersPanelOpenReducer(state = false, action) {
  switch (action.type) {
    case TOGGLE_FILTERS_PANEL:
      return !state;

    default:
      return state;
  }
}

/**
 * Export Lender Reducer
 * @type {Object}
 */
export default combineReducers({
  byId: byIdReducer,
  isFiltersPanelOpen: isFiltersPanelOpenReducer,
  isLoading: isLoadingReducer,
});

/**
 * Create Success Action Creator.
 * @param  {Object} resposne
 * @return {Object}
 */
function createSuccess(response) {
  analytics.productSearch.savedFilters.create();
  const payload = normalize(response.data, schema.search);
  return { type: CREATE_SUCCESS, payload };
}

/**
 * Fetch By Id Success Action Creator.
 * @param  {Object} response
 * @return {Object}
 */
function fetchByIdSuccess(response) {
  const payload = normalize(response.data, schema.search);
  return { type: FETCH_BY_ID_SUCCESS, payload };
}

/**
 * Fetch Success Action Creator.
 * @param  {Object} response
 * @return {Object}
 */
function fetchSuccess(response) {
  const payload = normalize(response.data, [schema.search]);
  return { type: FETCH_SUCCESS, payload };
}

/**
 * Create a new saved search.
 * @param  {Object} body
 * @return {Function}
 */
export function create(body) {
  return (dispatch) => {
    dispatch({ type: CREATE });
    return axios
      .post('/api/saved-searches', body)
      .then((response) => dispatch(createSuccess(response)))
      .catch((error) => dispatch({ type: CREATE_FAILED, error }));
  };
}

/**
 * Get all the saved searches.
 * @return {Function}
 */
export function fetch() {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get('/api/saved-searches')
      .then((response) => dispatch(fetchSuccess(response)))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}

/**
 * Get a search by id.
 * @param  {String} id
 * @return {Function}
 */
export function fetchById(id) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get(`/api/saved-searches/${id}`)
      .then((response) => dispatch(fetchByIdSuccess(response)))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}

/**
 * Remove a saved search.
 * @param  {String} id
 * @return {Function}
 */
export function remove(id) {
  return (dispatch) => {
    analytics.productSearch.savedFilters.delete();
    dispatch({ type: DELETE, payload: id });
    return axios
      .delete(`/api/saved-searches/${id}`)
      .catch((error) => dispatch({ type: DELETE_FAILED, error }))
      .then(() => fetchById(id));
  };
}

/**
 * Toggle Filters Panel.
 * @return {Function}
 */

export function toggleFiltersPanel() {
  return { type: TOGGLE_FILTERS_PANEL };
}
