import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { analytics } from 'libs';
import { Select } from 'components/common';
import { selectors } from 'state';
import Filter from './Filter';

export const LenderFilter = ({
  defaultMenuIsOpen = false,
  disabled,
  filters,
  isPro = false,
  lenders,
  onFilter,
  user,
}) => {
  const onChange = (options, action, option) => {
    if (action === 'clear') analytics.productSearch.filter({ lenders: 'all' });
    if (action === 'select-option') {
      analytics.productSearch.filter({ lenders: option.label });
    }
    const filter = { lenders: options?.map((opt) => opt.value) || null };
    onFilter(filter);
  };

  const getOptions = () => {
    const filteredLenders = user.features.is_pro ? lenders : lenders.filter((lender) => !lender.is_pro_only);

    const options = filteredLenders.map((lender) => ({
      label: lender.name,
      value: lender.id,
    }));

    return options;
  };

  const getSelectedOptions = () => {
    const options = getOptions();
    const lenderId = filters.lenders?.map((id) => parseInt(id, 10));

    return lenderId ? options.filter((option) => lenderId.includes(option.value)) : null;
  };

  return (
    <Filter name="Lenders" isPro={isPro}>
      <Select
        testId="filter-lenders"
        className="form-react-select"
        clearValue={null}
        defaultMenuIsOpen={defaultMenuIsOpen}
        disabled={disabled || isPro}
        isMulti
        onChange={onChange}
        options={getOptions()}
        placeholder="All"
        selected={getSelectedOptions()}
      />
    </Filter>
  );
};

LenderFilter.propTypes = {
  defaultMenuIsOpen: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    lenders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  }).isRequired,
  isPro: PropTypes.bool,
  lenders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      is_pro_only: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onFilter: PropTypes.func.isRequired,
  user: PropTypes.shape({
    features: PropTypes.shape({
      is_pro: PropTypes.bool,
    }).isRequired,
  }).isRequired,
};

const mapState = (state) => ({
  lenders: selectors.lenders.getAll(state),
  user: selectors.users.getCurrentUser(state),
});

export default connect(mapState)(LenderFilter);
