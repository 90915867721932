import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { analytics } from 'libs';
import {
  AwardIcon,
  BuildingsIcon,
  CreditCardIcon,
  FileTrayFullIcon,
  FinmoLogoIcon,
  LandmarkArrowsIcon,
  LandmarkIcon,
  LockIcon,
  MicrochipAiIcon,
  QuestionCircleIcon,
  ScaleUnbalancedIcon,
  SignOutIcon,
  UserCircleIcon,
  UserIcon,
  WaveTriangleIcon,
} from 'icons';
import Hamburger from './Hamburger';
import MobileNavGroup from './MobileNavGroup';
import MobileNavItem from './MobileNavItem';
import Nav from './Nav';
import PhoneNumberBadge from './PhoneNumberBadge';

const MobileNav = ({ canManageSubscriptions, hasPaymentMethod, isOpen, onToggle }) => {
  const { pathname } = useLocation();

  return (
    <div className={classnames('mobile-nav', { open: isOpen })}>
      <Hamburger onToggle={onToggle} />
      {isOpen && (
        <Nav className="mobile-nav__menu">
          <div className="mobile-nav__links">
            <div className="mobile-nav__navlinks">
              <MobileNavItem
                icon={<WaveTriangleIcon />}
                isActive={pathname.startsWith('/rates')}
                label="Rates"
                onClick={analytics.navigation.onMobile('rates')}
                testId="mobile-nav-navlink-rates"
                to="/rates"
              />
              <MobileNavItem
                icon={<ScaleUnbalancedIcon />}
                isActive={pathname.startsWith('/scenarios')}
                label="Scenarios"
                onClick={analytics.navigation.onMobile('scenarios')}
                testId="mobile-nav-navlink-scenarios"
                to="/scenarios/comparisons"
              />
              <MobileNavItem
                icon={<LandmarkIcon />}
                isActive={pathname.startsWith('/lenders')}
                label="Lenders"
                onClick={analytics.navigation.onMobile('lenders')}
                testId="mobile-nav-navlink-lenders"
                to="/lenders"
              />
              <MobileNavItem
                icon={<FileTrayFullIcon />}
                isActive={pathname.startsWith('/guidelines')}
                label="Policies"
                onClick={analytics.navigation.onMobile('guidelines')}
                testId="mobile-nav-navlink-guidelines"
                to="/guidelines"
              />
              <MobileNavItem
                icon={<LandmarkArrowsIcon />}
                isActive={pathname.startsWith('/alternative-lending')}
                label="Alternative Lending"
                onClick={analytics.navigation.onMobile('alternative-lending')}
                testId="mobile-nav-navlink-alternative-lending"
                to="/alternative-lending"
              />
              <MobileNavItem
                icon={<MicrochipAiIcon />}
                isActive={pathname.startsWith('/ai')}
                label="AI Assistant"
                onClick={analytics.navigation.onMobile('ai')}
                testId="mobile-nav-navlink-ai"
                to="/ai"
              />
              <MobileNavItem
                icon={<QuestionCircleIcon />}
                isExternalRoute
                label="Help Centre"
                onClick={analytics.navigation.onMobile('help-centre')}
                shouldOpenNewWindow
                testId="mobile-nav-navlink-help-centre"
                to="https://support.lenderspotlight.ca"
              />
            </div>
            <div className="mobile-nav__settings">
              <MobileNavItem
                icon={<FinmoLogoIcon />}
                isExternalRoute
                label="Switch to Finmo"
                onClick={analytics.navigation.onMobile('finmo')}
                shouldOpenNewWindow
                testId="mobile-nav-navlink-finmo"
                to="https://app.finmo.ca"
              />
              <PhoneNumberBadge>
                <MobileNavGroup icon={<UserCircleIcon />} name="settings" startExpanded={pathname.includes('settings')}>
                  <PhoneNumberBadge isMobile>
                    <MobileNavItem
                      icon={<UserIcon />}
                      isActive={pathname === '/settings'}
                      label="Profile"
                      onClick={analytics.navigation.onMobile('settings')}
                      testId="mobile-nav-navlink-settings"
                      to="/settings"
                    />
                  </PhoneNumberBadge>
                  <MobileNavItem
                    icon={<BuildingsIcon />}
                    isActive={pathname.startsWith('/settings/brokerages')}
                    label="Brokerages"
                    onClick={analytics.navigation.onMobile('brokerages')}
                    testId="mobile-nav-navlink-brokerages"
                    to="/settings/brokerages"
                  />
                  {canManageSubscriptions ? (
                    <MobileNavItem
                      icon={<AwardIcon />}
                      isActive={pathname.startsWith('/settings/subscriptions')}
                      label="Subscriptions"
                      onClick={analytics.navigation.onMobile('subscriptions')}
                      testId="mobile-nav-navlink-subscriptions"
                      to="/settings/subscriptions"
                    />
                  ) : (
                    <MobileNavItem
                      icon={<AwardIcon />}
                      isActive={pathname.startsWith('/settings/upgrade')}
                      label="Upgrade"
                      onClick={analytics.navigation.onMobile('upgrade')}
                      testId="mobile-nav-navlink-upgrade"
                      to="/settings/upgrade"
                    />
                  )}
                  {hasPaymentMethod && (
                    <MobileNavItem
                      icon={<CreditCardIcon />}
                      isActive={pathname.startsWith('/settings/payment')}
                      label="Payment Method"
                      onClick={analytics.navigation.onMobile('payment')}
                      testId="mobile-nav-navlink-payment"
                      to="/settings/payment-method"
                    />
                  )}
                  <MobileNavItem
                    icon={<LockIcon />}
                    isExternalRoute
                    label="Terms of Service"
                    onClick={analytics.navigation.onMobile('terms-of-service')}
                    shouldOpenNewWindow
                    testId="mobile-nav-navlink-terms-of-service"
                    to="https://lendesk.com/terms-spotlight"
                  />
                  <MobileNavItem
                    icon={<LockIcon />}
                    isExternalRoute
                    label="Privacy Policy"
                    onClick={analytics.navigation.onMobile('privacy-policy')}
                    shouldOpenNewWindow
                    testId="mobile-nav-navlink-privacy-policy"
                    to="https://lendesk.com/privacy-lendesk"
                  />
                </MobileNavGroup>
              </PhoneNumberBadge>
              <MobileNavItem
                icon={<SignOutIcon />}
                isExternalRoute
                label="Logout"
                onClick={analytics.navigation.onMobile('logout')}
                testId="mobile-nav-navlink-logout"
                to="/logout"
              />
            </div>
          </div>
        </Nav>
      )}
    </div>
  );
};

MobileNav.propTypes = {
  canManageSubscriptions: PropTypes.bool.isRequired,
  hasPaymentMethod: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default MobileNav;
